import React, { Component } from 'react';

class Brief extends Component {
    render() {
        return (

            <form>
            <div class="form-group brief-form">
              <label for="briefContact">Email</label>
              <input type="text" class="form-control" id="briefContact" aria-describedby="emailHelp" placeholder="someone@example.com "/>
            </div>
            <div class="form-group brief-form">
            <label for="briefContact">Mobile</label>
            <input type="text" class="form-control" id="briefContact" aria-describedby="emailHelp" placeholder="Eg. +255 785 XXX..."/>
          </div>
            <div class="form-group brief-form">
              <label for="BriefContact">Brief</label>
              <textarea  rows="6" type="text" class="form-control" id="exampleInputPassword1" placeholder="Type something"/>
            </div>
            <button type="submit" class="btn btn-primary brief-btn">Submit</button>
          </form>
        );
    }
}

export default Brief;