import React from "react";
import '../sass/navbar.scss';
import AnchorLink from "react-anchor-link-smooth-scroll"


const Navbar=(props)=>(
    <div>
    <div className={`navbar-wrapper ${props.mainTheme}`}>
            <div className="navbar-brand">
                    {props.children}
            </div>
            <div className="navbar-links-wrapper">
            <ul className={`navbar-links ${props.theme}`}>

        <li className="navbar-link lets-talk-link ">
                <AnchorLink href="#brief" >Let's talk</AnchorLink>
            </li>
            </ul>
            </div>
    </div>
    </div>
);

export default Navbar;