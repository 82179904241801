import React from 'react';
import './sass/main.scss';
import Home from './components/Home/';
import About from "./components/About/";
import {BrowserRouter, Route, Switch} from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
    <div className="App">
      <Switch>
      <Route path="/about" component={About}/>
      <Route path="/" component={Home}/>
      </Switch>
    </div>
    </BrowserRouter>
  );
}

export default App;
