import React, { Component } from 'react';
import Product from "../images/icons/product.svg";


class DoTile extends Component {


    

    render() {
        return (
            <div className="col-md-6 mt-4 do-tile-wrapper">
           <div className="row">
           <div className="col-md-2 do-icon-wrapper">
           <img src={this.props.image} className="do-icon" alt="Product strategy"/>
   </div>
   <div className="col-md-10 do-desc">
   <h1 className="do-title">{this.props.title}</h1>
   <p className="do-desc" >{this.props.children}</p>
   </div>
           </div>
            </div>
        );
    }
}

export default DoTile;