import React, { Component } from 'react';
import UNESCO from "../../images/unesco.jpg";
import TTCL from "../../images/TTCL.jpg";
import wefarm from "../../images/wefarm.jpg";
import ecoshilling from "../../images/ecoshilling.jpg";



class Work extends Component {
    render() {
        return (
            <div className="container">
                <div className="row">
                <div className="col-md-6 mt-3">
                    <div className="work-item-wrapper">
                            <div className="work-title">
                            <h1>UNESCO</h1>
                            <p>UI/UX Design・Mobile & Web application development</p>
                            </div>
                            <img className="work-image" src={UNESCO}/>
                    </div>
                </div>
                <div className="col-md-6 mt-3">
                <div className="work-item-wrapper">
                <div className="work-title">
                <h1>TTCL</h1>
                <p>UI/UX Design</p>
                </div>
                <img className="work-image" src={TTCL}/>
                </div>
                </div>
                <div className="col-md-6 mt-3">
                <div className="work-item-wrapper">
                <div className="work-title">
                <h1>Wefarm</h1>
                <p>Branding</p>
                </div>
                <img className="work-image" src={wefarm}/>
                </div>
                </div>
                <div className="col-md-6 mt-3">
                <div className="work-item-wrapper">
                <div className="work-title dark-title">
                <h1>Ecoshilling</h1>
                <p>UI/UX Design・Mobile & Web application development</p>
                </div>
                <img className="work-image" src={ecoshilling}/>
                </div>
                </div>
                </div>
                </div>
        );
    }
}

export default Work;