import React, { Component } from 'react';
import '../../sass/about.scss';
import "../../sass/home.scss";
import BrandLight from '../../images/brandLight.png'
import Navbar from "../../shared/Navbar";


class About extends Component {
    render() {
        return (
            <div>
                <Navbar theme="navbar-links-light">
                <img src={BrandLight} className="navbar-brand" alt="hype interactive logo"/>
                </Navbar>
                    <div className="about-intro-wrapper">
                        <div className="about-intro-text-wrapper">
                        <h1 className="about-intro-text">Make something that matters</h1>
                        </div>
                    </div>
            </div>
        );
    }
}

export default About;