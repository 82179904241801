import React, { Component } from "react";
import Navbar from "../../shared/Navbar";
import Product from "../../images/icons/product.svg";
import Interaction from "../../images/icons/interaction.svg";
import Mobile from "../../images/icons/mobile.svg";
import Web from "../../images/icons/web.svg";
import Tony from "../../images/efforts/Tony.png";
import Yali from "../../images/efforts/yali.png";
import Intel from "../../images/efforts/intel.png";
import Seeds from "../../images/efforts/seeds.png";
import Idea from "../../images/efforts/idea.png";
import finca from "../../images/clients/finca.png";
import mcf from "../../images/clients/mastercard.png";
import unesco from "../../images/clients/unesco_logo.png";
import palladium from "../../images/clients/palladium.png";
import wefarm from "../../images/clients/wefarm.png";
import GRSP from "../../images/clients/GRSP.png";
import TTCL from "../../images/clients/ttcl.png";
import TBI from "../../images/clients/tanzaniaBora.png";
import IREX from "../../images/clients/irex.png";
import ribbon from "../../images/ribbon.png";

import Galaxy from "../../images/Galaxy.png";
import Hands from "../../images/hands.png";
import Arrow from "../../shared/arrow";
import "../../sass/home.scss";
import Brand from "../../images/brand.png";
import LR from "../../images/iphoneX.png";
import TextLoop from "react-text-loop";
import DoTile from "../../shared/doTile";
import Work from "./work";
import Brief from "./brief";
import Hype from "../../shared/hype.js";
import {
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaLinkedin,
  FaFacebookSquare
} from "react-icons/fa";

class Home extends Component {
  render() {
    return (
      <div>
        <Navbar mainTheme="home-navbar">
          <img
            src={Brand}
            className="navbar-brand"
            alt="hype interactive logo"
          />
        </Navbar>
        <div className="hero-wrapper">
          <div className="hero-container">
            <div className="hero-contents">
              <div className="intro-contents">
                <div className="row">
                  <div className="col-md-6">
                    <h1 className="hero-text">
                      We help you build great products.
                    </h1>
                    <div className="divider"></div>
                    <p className="hero-intro">
                      We work with amazing companies, startups and people in
                      Africa to build things that matter.
                    </p>
                  </div>
                  <div className="col-md-6">
                    <img
                      src={Galaxy}
                      className="phone floating"
                      alt="hype interactive logo"
                    />
                    <img
                      src={LR}
                      className="phone-alt floating-alt"
                      alt="hype interactive logo"
                    />
                  </div>
                </div>
              </div>
              <div className="scroll-wrapper">
                <div class="arrow"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="work-wrapper">
          <Work />
        </div>
        <div className="what-we-do-wrapper">
          <div className="container">
            <h1 className="centered-title">What we offer</h1>
            <p className="centered-desc">
             Leveraging our in-depth knowledge of the industry, we are proficient in crafting digital products. We are capable of turning a napkin sketch to a real shipped product that people love.{" "}
            </p>
            <div className="row what-we-offer-row">
              <DoTile title="Product Strategy" image={Product}>
              We work with our clients to define what their product is, what it does, and how it works. We pair innovation with user experience in the best way possible.
              </DoTile>
              <DoTile title="UI/UX Design" image={Interaction}>
              For digital products and services to resonate with audiences, we ensure UX design informs all decisions made from landing to leaving.
              </DoTile>
              <DoTile title="Mobile Apps" image={Mobile}>
              Mobile is more important than ever and we're here to help you take advantage of the most important development of the last 15 years.
              </DoTile>
              <DoTile title="Web Apps" image={Web}>
              We obsess over code. This is what allows us to promise websites that look beautiful, communicate brilliantly, and run quietly under the hood.
              </DoTile>
            </div>
          </div>
        </div>
        <div className="efforts-wrapper">
          <h1 className="centered-title">Mentored by changemakers</h1>
          <p className="centered-desc">
            We have been priviledged to associate with and get mentored from
            global leading change makers and hubs on Entrepreneurship, Product
            development and Design thinking .{" "}
          </p>
          <div className="container logo-grid">
            <div className="row">
              <div className="col-md-4 logo-grid-item">
                <img
                  src={Tony}
                  className="logo-grid-img"
                  alt="Tony elemulu logo"
                />
              </div>
              <div className="col-md-4 logo-grid-item">
                <img src={Yali} className="logo-grid-img" alt="YALI logo" />
              </div>

              <div className="col-md-4 logo-grid-item">
                <img src={Intel} className="logo-grid-img" alt="Intel logo" />
              </div>

              <div className="col-md-4 offset-md-2 logo-grid-item">
                <img src={Seeds} className="logo-grid-img" alt="YALI logo" />
              </div>

              <div className="col-md-4 logo-grid-item">
                <img src={Idea} className="logo-grid-img" alt="YALI logo" />
              </div>
            </div>
          </div>
        </div>
        <div className="clients-wrapper">
          <h1 className="centered-title">
            {" "}
            Meet our{" "}
            <TextLoop noWrap={false} fade={true} mask={true}>
              <span>Clients</span>
              <span>Partners</span>
              <span>Friends</span>
            </TextLoop>{" "}
          </h1>
          <p className="centered-desc">
            We are proud to have gain trust and work with companies,
            organizations and startups to design products that have transformed
            people's lives and organizational processes.{" "}
          </p>
          <div className="container logo-grid">
            <div className="row">
              <div className="col-md-3 logo-grid-item">
                <img
                  src={wefarm}
                  className="logo-grid-img wefarm-logo"
                  alt="FINCA Mircorfinance Bank logo"
                />
              </div>
              <div className="col-md-3 logo-grid-item">
                <img
                  src={mcf}
                  className="logo-grid-img"
                  alt="Mastercard foundation logo"
                />
              </div>
              <div className="col-md-3 logo-grid-item">
                <img src={unesco} className="logo-grid-img" alt="UNESCO logo" />
              </div>
              <div className="col-md-3 logo-grid-item">
                <img
                  src={TTCL}
                  className="logo-grid-img"
                  alt="Palladium logo"
                />
              </div>

              <div className="col-md-3 offset-md-2 logo-grid-item mt-3">
                <img src={TBI} className="logo-grid-img" alt="GRSP logo" />
              </div>

              <div className="col-md-3 logo-grid-item mt-3">
                <img src={finca} className="logo-grid-img" alt="wefarm logo" />
              </div>

              <div className="col-md-3 logo-grid-item mt-3">
                <img
                  src={palladium}
                  className="logo-grid-img"
                  alt="TTCL logo"
                />
              </div>

              <div className="col-md-3 offset-md-3 logo-grid-item mt-4">
                <img src={IREX} className="logo-grid-img" alt="TTCL logo" />
              </div>

              <div className="col-md-3 logo-grid-item mt-4">
                <img src={GRSP} className="logo-grid-img" alt="YALI logo" />
              </div>
            </div>
          </div>
        </div>
        <div className="contact-wrapper" id="brief">
          <div className="container">
            <h1 className="centered-title">
              <TextLoop fade={true} mask={true}>
                <span>Get in touch</span>
                <span>Wasiliana nasi</span>
                <span>Entrez en contact</span>
                <span>الحصول على اتصال.</span>
                <span>保持联系</span>
                <span>Ponte en contacto</span>
              </TextLoop>
            </h1>
            <p className="centered-desc">Ready to start a project? Let's talk . </p>
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <Brief />
              </div>
            </div>
            <div className="hands-wrapper">
              <img src={Hands} className="hands-img" alt="Hands" />
            </div>
          </div>
        </div>

        <div className="footer-wrapper">
          <div className="container">
            <h3 className="centered-title light-title">
              Make something that matters.
            </h3>
            <div className="finishing-wrapper">
              <ul className="contacts-list">
                <li className="contact-items bolded-text">Hype Interactive</li>
                <li className="contact-items">House No.46, Seminar Road</li>
                <li className="contact-items">Sinza</li>
                <li className="contact-items">P.O Box 63031</li>
                <li className="contact-items">Dar es Salaam, Tanzania</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="last-footer-wrapper">
          <div className="container last-footer-contents">
            <p>2020&copy;Hype Interactive</p>
            <div className="icons-wrapper">
              <ul className="social-media-icons">
                <li className="social-media-icon">
                  <a>
                    <FaFacebookSquare />
                  </a>
                </li>
                <li className="social-media-icon">
                  <a>
                    <FaInstagram />
                  </a>
                </li>
                <li className="social-media-icon">
                  <a>
                    <FaTwitter />
                  </a>
                </li>
                <li className="social-media-icon">
                  <a>
                    <FaLinkedin />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
